import React from 'react'
import Navigational_Bar from './component/Navigational_Bar';
import HomePage from './Pages/index'

function App() {
  return (
    <div className="App">
      <Navigational_Bar/>
    
          <HomePage/>
  
     
    </div>
  );
}

export default App;
