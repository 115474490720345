import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import "../stylesheets/home_hero.css"

export default function Home_Hero() {
  return (
    <div>
    <Container>    
    <Row >
    <Col  md={12} className="holder" >
  <Carousel className="carousel">
      <Carousel.Item >
      <Image   src="./OSUBI/osubi 9.jpg" rounded />
        <Carousel.Caption>
          <h3>Third slide label</h3>
          <p>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
          </p>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item >
      <Image   src="./OSUBI/osubi 10.jpg" rounded />
        <Carousel.Caption>
          <h3>Third slide label</h3>
          <p>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
          </p>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item >
      <Image   src="./OSUBI/osubi 8.jpg" rounded />
        <Carousel.Caption>
          <h3>Third slide label</h3>
          <p>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur.
          </p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
    </Col>
    </Row>
</Container>

    </div>
  )
}
