import React from 'react'
import Home_Hero from '../component/Home_Hero'
import "../stylesheets/home_page.css"
import Greeting_message from '../component/Greeting_message'
import Facilities from '../component/Facilities'



export default function index() {
  const mMessage= 'Ditobodity'
  return (

    <React.Fragment>
        <div className='upper-div'>
              <Home_Hero/>
        </div> 
        <div className='mid-div'>
          

          
        <Greeting_message message={mMessage}/>
        <Facilities/>
          
          </div>    
            
            

       
    
    </React.Fragment>
  )
}
