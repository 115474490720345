import React from 'react'
import "../stylesheets/navbar.css"

import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

export default function Navigational_Bar 
() {
  return (
    <React.Fragment>
 <Navbar expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand href="#home"> <Image className='logo-img'  src="./OSUBI/osubi 7.jpg" rounded />  </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="#home"><b>Home</b></Nav.Link>
            <Nav.Link href="#link"><b>Gallery</b></Nav.Link>
            <Nav.Link href="#link"><b>Book a room</b></Nav.Link>
            <Nav.Link href="#link"><b>About us</b></Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>


    </React.Fragment>
        
    
  )
}






