import React from 'react'
import { Container,Row, Col, Image, Button } from 'react-bootstrap'
import "../stylesheets/facilities.css"
export default function Facilities() {
  return (
    <>
   


    <Container>
    <h4 className='display-5 my-5'>Facilities</h4>
        <Row>

           
                    <Col md={6} className='div1'>
                        <div>
                            <Image src='./OSUBI/osubi 1.jpg' fluid />

                        </div>

                    </Col>
                        <Col md={6} className='div2'>
                          <div>
                          <p>
                            We have a standard swimmming pool, a barbeque stand where you can get our delicious fishes at affordable prices.
                            </p> 
                          <Button>View Gallery</Button>
                          </div>
                           

                        </Col>

                  
         <Row className='clearfix'>
                 <Col md={6} className='div3'>
                        <div>
                        <h3>Let's take your mind off stress</h3>
                            <p>We treat you like our only customer, so relax and enjoy yourself. We got you covered. </p>



                        </div>

                    </Col>
                        <Col md={6} className='div4 '>
                        <Image  src='./OSUBI/stressed.jpg' fluid/>
                        </Col>

            
            
         </Row>        

        </Row>



    </Container>






    
    
    </>
  )
}
